.App-social-icon .social-svg:hover {
  background-color: #ffffff;
}

.App-social-icon-navbar .social-svg:hover {
  background-color: #00ff89;
}

@media (prefers-reduced-motion: no-preference) {
  .App-leburc-logo-border {
    animation: App-leburc-logo-border-spin infinite 70s linear;
  }
}

@keyframes App-leburc-logo-border-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}